import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { API_ROUTES } from 'src/app/core/http/api-routes';
import { HttpService } from 'src/app/core/http/http.service';
import { AttendenceService } from 'src/app/core/services/attendence.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastrService } from 'src/app/core/services/toastr.service';

@Component({
  selector: 'app-dash-absents-module',
  templateUrl: './dash-absents-module.component.html',
  styleUrls: ['./dash-absents-module.component.css']
})
export class DashAbsentsModuleComponent implements OnInit {
  total: any = []
  attendeceSections: any = []
  finance: any = []
  re;
  stdSection;
  absentCounts;
  shiftTwo;
 
  attadenceSummary: any = [];
  isAdmin: boolean
  constructor(
    private toastr: ToastrService,
    private httpApi: HttpService,
    private attendenceService: AttendenceService,
    private elementRef: ElementRef,
    private auth: AuthService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DashAbsentsModuleComponent>
  ) { }


  async ngOnInit() {
    this.re  = await this.httpApi.listAll(API_ROUTES.DASH_TOTAL_ATTENDENCEE).toPromise();
    console.log(this.re);

    if(this.re.classSections){
      this.stdSection=this.re.classSections 
  }
  if(this.re.absentCounts){ 
      this.absentCounts=this.re.absentCounts 
      
  }
  if(this.re.shiftTwo){ 
    this.shiftTwo=this.re.shiftTwo 
    
}
  }

}
