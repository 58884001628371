<div class="row" style="gap: 0px 15px">
 
  <div    class="col-4"    style="  background-color: #fff;   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 4px;">
   
  <button type="button" class="btn btn-primary"   (click)="dialogRef.close()">Close</button>
    <div
      class=""
      style="overflow-y: auto; max-height: 300px; font-size: 9px !important"  *ngIf="stdSection"
    >
      <div class="card">
        <div class="d-flex justify-content-between align-items-center">
          <span class="font-weight-bold">Shift 1 UnAttended Classes</span>
        </div>

        <div class="mt-3" *ngFor="let section of stdSection">
          <div class="section-card p-3 mb-3 shadow-sm rounded d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row align-items-center">
              <span class="star mr-3">
                <i class="fa fa-star yellow"></i>
              </span>
              <div class="d-flex flex-column">
                <span class="section-name">{{ section.class_section }}</span>
              </div>
            </div>
          
            <button class="btn btn-outline-primary btn-sm" [routerLink]="['/attendance/add-new']" (click)="dialogRef.close()">Take Attendence</button>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>

  <div
    class="col-3"
    style="
      background-color: #fff;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 4px;
    "
    *ngIf="absentCounts"
  >
    <div
      class=""
      style="overflow-y: auto; max-height: 300px; font-size: 9px !important"
    >
      <div class="card">
        <div class="d-flex justify-content-between align-items-center">
          <span class="font-weight-bold">Daily Most Absent Classes</span>
        </div>

        <div class="mt-3" *ngFor="let absent of absentCounts">
          <div class="absent-card p-3 mb-3 shadow-sm rounded d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row align-items-center">
              <span class="star mr-3">
                <i class="fa fa-star yellow"></i>
              </span>
              <div class="d-flex flex-column">
                <span class="class-info">{{ absent.class_name }} - {{ absent.section_name }}</span>
              </div>
            </div>
            <span class="absent-count">{{ absent.absent_students_count }}</span>
          </div>
        </div>
        

      </div>
    </div>
  </div>

  <div    class="col-4"    style="  background-color: #fff;   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 4px;">
   
    
      <div
        class=""
        style="overflow-y: auto; max-height: 300px; font-size: 9px !important"  *ngIf="shiftTwo"
      >
        <div class="card">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bold">Shift 2 UnAttended Classes</span>
          </div>
  
          <div class="mt-3" *ngFor="let section of shiftTwo">
            <div class="section-card p-3 mb-3 shadow-sm rounded d-flex justify-content-between align-items-center">
              <div class="d-flex flex-row align-items-center">
                <span class="star mr-3">
                  <i class="fa fa-star yellow"></i>
                </span>
                <div class="d-flex flex-column">
                  <span class="section-name">{{ section.class_section }}</span>
                </div>
              </div>
            
              <button class="btn btn-outline-primary btn-sm" [routerLink]="['/attendance/add-new']" (click)="dialogRef.close()">Take Attendence</button>
            </div>
          </div>
          
        </div>
      </div>
    
    </div>
  
</div>

 